'use client';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import Image from 'next/image';

import { ThemeSelectors } from '@/store';

const BackgroundImageLayout = () => {
	const theme = useSelector(ThemeSelectors.themeSelector);

	return (
		<div className="fixed inset-0 z-[-1]">
			<Image
				src={theme.bg_image}
				fill
				alt="Lucky Draw"
				className="bg-fixed object-cover object-bottom"
				placeholder="blur"
			/>
		</div>
	);
};

export default memo(BackgroundImageLayout);
